import React from "react";
import moment from "moment";
import {
  canCancel,
  getStatusReservacion,
  getStatusReservacionString,
} from "../../utils";
import { useContext } from "react";
import useBranch from "../../hooks/useBranch";
import { ModalContext } from "../../context/ModalContext";
import ClassReservationRating from "./ClassReservationRating";

const ReservacionRow = ({ reservation, handleCancel }) => {
  const { modalComponent } = useContext(ModalContext);
  const { branch } = useBranch();

  const handleRating = () => {
    modalComponent(
      "Calificar Clase",
      <ClassReservationRating
        handleCancel={handleCancel}
        class_reservation_id={reservation.class_reservation_id}
      />
    );
  };

  const renderInstructorName = (class_reservation) => {
    if (class_reservation.single_class !== null) {
      const instructors = class_reservation.single_class.class_instructors;
      if (instructors.length > 0) {
        if (instructors.length === 1) {
          return instructors[0].name;
        } else {
          return instructors.map(({ name }) => name).join(", ");
        }
      }
    }
  };

  const renderRating = () => {
    if (getStatusReservacionString(reservation) === "Exitosa") {
      if (reservation.class_stars === null) {
        return (
          <button
            onClick={handleRating}
            className="btn btn-sm btn-outline-dark"
          >
            <i className="fa fa-star me-1"></i> Calificar
          </button>
        );
      }
      return (
        <span>
          {reservation.class_stars} <i className="fa fa-star text-warning"></i>
        </span>
      );
    }
  };

  return (
    <tr className="border-bottom">
      <td>{reservation.single_class.class_type.name}</td>
      <td>{renderInstructorName(reservation)}</td>
      <td>
        {moment(reservation.single_class.class_date)
          .utc()
          .format("DD MMM YYYY HH:mm")}
      </td>
      <td>{moment(reservation.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>{getStatusReservacion(reservation)}</td>
      <td>
        {renderRating()}
        {canCancel(reservation.single_class, branch) &&
          reservation.deletedAt === null && (
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => handleCancel(reservation)}
            >
              <i className="fa fa-times me-1"></i> Cancelar
            </button>
          )}
      </td>
    </tr>
  );
};

export default ReservacionRow;
