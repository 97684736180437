import React from "react";
import useEmblaCarousel from "embla-carousel-react";

import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";

import "./embla.css";
import { formatMonto } from "../../utils";
import { navigate } from "@reach/router";

const OPTIONS = { align: "start" };

const MonterreyPackagesCarousel = ({ packages }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const renderSubscriptionInterval = (class_package) => {
    const periods = {
      day: {
        plural: "DÍAS",
        singular: "DÍA",
      },
      month: {
        plural: "MESES",
        singular: "MES",
      },
      year: {
        plural: "AÑOS",
        singular: "AÑO",
      },
    };

    if (class_package?.subscription_interval) {
      const currentPeriod = periods[class_package.subscription_period];
      const periodType =
        class_package.subscription_interval > 1 ? "plural" : "singular";

      return (
        <p className={`content__subscription ${class_package.sale_price !== null ? "mb-0": ""}`}>
          CADA {class_package.subscription_interval} {currentPeriod[periodType]}
        </p>
      );
    }
    return <p className="content__subscription"></p>;
  };

  const renderPricePerClass = (class_package) => {
    if (class_package?.class_amount && class_package?.price) {
      if(String(class_package.title).toLowerCase().includes("ilimitado")) return "";
      const price = class_package.sale_price !== null ? Number(class_package.sale_price) : Number(class_package.price);
      const pricePerClass = formatMonto(
        price / Number(class_package.class_amount)
      );
      return (
        <h5 className="content__feature">
          <i className="fas fa-check me-2" />${Number(pricePerClass).toFixed(2)}{" "}
          por clase
        </h5>
      );
    }
  };

  const renderClassPackageSlots = class_package => {
    let message = "Reserva tus clases presenciales"
    if(!class_package.slots || class_package.slots === null) { 
      return message;
    }
    const slots = String(class_package.slots).split(",");
    if(slots.length > 1) {
      message += " en los horarios " + slots.sort((a,b) => a < b ? -1 : 1).join(", ");
    } else {
      message += " en el horario de" + slots[0];
    }
    return message;
  }

  const renderPackages = () => {
    if (Array.isArray(packages)) {
      let unlimited = packages.filter(({ title }) => String(title).toLowerCase().includes("ilimitado"));
      let notunlimited = packages.filter(({ title }) => !String(title).toLowerCase().includes("ilimitado"));
      notunlimited = notunlimited.sort((a,b) => {
        const aNumbers = a.title.match(/\d+/);
        const bNumbers = b.title.match(/\d+/);
        if(aNumbers && bNumbers && aNumbers !== null && bNumbers !== null) {
          const aClasses = parseInt(aNumbers[0]);
          const bClasses = parseInt(bNumbers[0]);
          return aClasses < bClasses ? -1 : 1;
        }
        return a.class_amount < b.class_amount ? -1 : 1;
      });
      unlimited = unlimited.sort((a,b) => {
        if(a.sale_price === null) return -1;
        if(a.subscription_period === "year" && b.subscription_period !== "year") return -1;
        return a.subscription_interval < b.subscription_interval ? -1 : 1;
      });
      return notunlimited.concat(unlimited).map((class_package) => {
        return (
          <div className="packages__slide" key={class_package.class_package_id}>
            <div
              className="embla__slide__package"
              style={{
                backgroundColor: "FAFAFA",
              }}
            >
              <div className={`package-card__header ${class_package.sale_price !== null ? "text-white monterrey-degraded-btn" : ""}`}>
                <p className="header__name">{class_package.title}</p>
                <p className="header__expiration">
                  {class_package.expiration_days} días de Vigencia.
                </p>
              </div>

              <div className="package-card__content" style={{ flex: 1 }}>
                {class_package.sale_price !== null && class_package.sale_price && (
                  <p className="content__subscription text-danger mb-0">
                    <del>${formatMonto(class_package.price)} MXN</del>
                  </p>
                )}
                <p className="content__price">
                  {(class_package.sale_price !== null &&
                    class_package.sale_price === 0) ||
                  class_package.price === 0 ? (
                    "GRATIS"
                  ) : (
                    <>
                      $
                      {class_package.sale_price !== null
                        ? class_package.sale_price
                        : class_package.price}{" "}
                      MXN
                    </>
                  )}
                </p>
                {renderSubscriptionInterval(class_package)}
                <div className="content__features">
                  {class_package.class_amount > 0 && (
                    <h5 className="content__feature">
                      <i className="fas fa-check me-2" />
                        {renderClassPackageSlots(class_package)}
                    </h5>
                  )}

                  {class_package.class_amount > 1 &&
                    class_package.free_trial_length <= 0 &&
                    class_package.include_online && (
                      <>
                        <h5 className="content__feature">
                          <i className="fas fa-check me-2" />
                          Acceso a TBM Online Gratis
                        </h5>
                      </>
                    )}

                  {renderPricePerClass(class_package)}
                </div>

                <button
                  type="button"
                  className="btn class-package__cta w-100 text-white bold px-4 monterrey-degraded-btn mb-3 mt-auto"
                  style={{
                    borderRadius: "40px",
                  }}
                  onClick={() =>
                    navigate(`/checkout/${class_package.class_package_id}/`)
                  }
                >
                  {class_package.price === 0 ? "RESERVAR" : "COMIENZA YA"}
                  <i className="fas fa-arrow-right ms-2" />
                </button>
                <p className="text-black mb-0 packages-carousel__bottom w-100 mb-2 fw-normal">
                  El cargo se realizará automáticamente a tu forma de pago.
                </p>
                <p className="text-black packages-carousel__bottom w-100 mb-2 fw-normal">
                  Puedes cancelar cuando quieras.
                </p>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <>
      <section className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">{renderPackages()}</div>
        </div>

        <div className="embla__controls">
          <div className="embla__buttons">
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </div>

          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={"embla__dot".concat(
                  index === selectedIndex ? " embla__dot--selected" : ""
                )}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MonterreyPackagesCarousel;
