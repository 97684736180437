import React from "react";
import { formatMonto, getValue } from "../../utils";
import PaqueteFeatures from "./PaqueteFeatures";
import { features } from "../../utils/paquetes";

const PaqueteCard = ({ paquete, action, buttonTitle, className }) => {
  const onSale = () => paquete.sale_price && paquete.sale_price !== null;

  const { title, sale_price, price, is_special_event } = paquete;
  return (
    <div className={"col-12 col-md-6 col-lg-4 mb-3 px-0 px-md-2"}>
      <div
        className={`p-3 card shadow-sm no-scale position-relative ${
          is_special_event ? "bg-dark text-white" : "bg-light border"
        }`}
      >
        {sale_price && sale_price !== null && (
          <div className="bg-danger text-white sale-ribbon p-2 px-4 shadow-sm">
            SALE
          </div>
        )}
        <h3 className={`mb-2 ${is_special_event ? "text-warning" : ""}`}>
          {title}
        </h3>
        <div>
          {paquete.expiration_days !== null && (
            <p className="d-inline-block align-items-center mb-2">
              <span className="badge bg-primary me-1">
                {paquete.expiration_days} días de Vigencia
              </span>{" "}
            </p>
          )}
          {paquete.class_amount > 0 && (
            <ul style={{ listStyleType: "none" }} className="ps-0 ms-0">
              <li>
                <i className="fa fa-check me-1 text-primary" />
                Reserva tus clases presenciales
              </li>
              {getValue(paquete, "include_online", "boolean") && (
                <li>
                  <i className="fa fa-check me-1 text-primary" />
                  Acceso a TBM Online
                </li>
              )}
            </ul>
          )}
          {paquete.include_online && paquete.class_amount < 1 && (
            <PaqueteFeatures features={features} />
          )}
          {onSale() && (
            <span className="strike-through d-block me-3 text-danger">
              {"$"}
              {formatMonto(price)} MXN
            </span>
          )}
          <h4 className="mb-2">
            {"$"}
            {formatMonto(onSale() ? sale_price : price)}
            {" MXN"}{" "}
            {paquete.is_subscription &&
              `cada ${
                paquete.subscription_interval === 1
                  ? ""
                  : paquete.subscription_interval
              }${
                paquete.subscription_period === "month"
                  ? paquete.subscription_interval === 1
                    ? "mes"
                    : " meses"
                  : paquete.subscription_period === "day"
                  ? " días"
                  : paquete.subscription_interval === 1
                  ? "año"
                  : " años"
              }`}
          </h4>
          <ul className="ps-3">
            <li className="small">
              El cargo se realizará automáticamente a tu forma de pago.
            </li>
            <li className="small">Puedes cancelar cuando quieras</li>
          </ul>
          <button
            className={
              className ? className : "btn btn-primary bold btn-block w-100"
            }
            onClick={action}
          >
            {buttonTitle ? buttonTitle : "Comprar"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaqueteCard;
