import React, { useContext, useEffect } from "react";
import "./MonterreyLandingPackages.css";
import useBranch from "../../hooks/useBranch";
import { PackagesContext } from "../../context/PackageContext";
import { PurchasesContext } from "../../context/PurchasesContext";
import MonterreyPackagesCarousel from "../paquetes/MonterreyPackagesCarousel";

const MonterreyLandingPackages = () => {
  const { branch } = useBranch();
  const { paquetes, getPaquetes } = useContext(PackagesContext);
  const { getPurchases } = useContext(PurchasesContext);

  useEffect(() => {
    getPaquetes();
    getPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPackages = () => {
    if (Array.isArray(paquetes)) {
      let paquetesRender = paquetes
        ?.filter(({ class_amount }) => class_amount > 0)
        .sort((a, b) => (a.class_amount < b.class_amount ? -1 : 1));

      const class_package_id = branch?.branch_id === 1 ? 19304 : 19301;
      const class_package_title =
        branch?.branch_id === 1
          ? "Clase de Prueba"
          : "Clase de Prueba";
      paquetesRender?.unshift({
        class_package_id: class_package_id,
        branch_id: branch?.branch_id,
        title: class_package_title,
        include_online: false,
        expiration_days: null,
        class_amount: 1,
        price: 0,
      });

      return paquetesRender;
    }
    return [];
  };

  return (
    <div id="scrollspyMtyPackages" className="container-fluid py-5 bg-white">
      <h2 className="h1 text-center text-black packages-carousel__title">
        ELIGE TU MEMBRESIA
      </h2>
      <p className="text-black text-center packages-carousel__subtitle">
        Todos nuestros paquetes presenciales incluyen TBM ONLINE de forma
        ilimitada.
      </p>

      <div className="container-fluid packages-carousel__container">
        <MonterreyPackagesCarousel packages={getPackages()} />
      </div>
    </div>
  );
};

export default MonterreyLandingPackages;
